import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";

function LogRocketInitializer({ appId }: { appId: string }) {
    LogRocket.init(appId);
    setupLogRocketReact(LogRocket);
    return null;
}

LogRocketInitializer.displayName = 'LogRocketInitializer';

export default LogRocketInitializer;